import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import './CalendarPage.css';
import NavBar from '../Components/NavBar';
import LogInView from '../Components/LogInView';
import EventView from '../Components/EventView';
import NewEventView from '../Components/NewEventView';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/sass/styles.scss';

import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore, Timestamp, collection, addDoc, query, where, onSnapshot, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

let config = {
    apiKey: "AIzaSyDvmsVvmzscjXmYC7kvFsvWm4tABXg2J7g",
    authDomain: "endofleaseccb.com.au",
    databaseURL: "https://end-of-lease-ccb.firebaseio.com",
    projectId: "end-of-lease-ccb",
    storageBucket: "end-of-lease-ccb.appspot.com",
    messagingSenderId: "61871480016",
    appId: "1:61871480016:web:15a9c46d7276c90d4752d5",
    measurementId: "G-1LH2202L19"
};

const app = getApps().length ? getApp() : initializeApp(config);
export const db = getFirestore(app);
const auth = getAuth(app);

const localizer = momentLocalizer(moment)

class CalendarPage extends Component {
    constructor() {
        super();

        this.state = { 
            bookings: [],
            showLoginView: false,
            showNewEntryView: false,
            selectedBooking: null,
            bookingEnabled: false,
            defaultUsername: null,
            defaultPassword: null
        }

        this.openUrl = this.openUrl.bind(this);
        this.newEntry = this.newEntry.bind(this);
        this.bookingToggled = this.bookingToggled.bind(this);
        this.setUpBookingListner = this.setUpBookingListner.bind(this);
        this.setUpConfigListner = this.setUpConfigListner.bind(this);
    }

    componentDidMount() {
        onAuthStateChanged(auth, firebaseUser => {
            if (firebaseUser) {
                this.setUpBookingListner();
                this.setUpConfigListner();

                if (this.state.showLoginView) {
                    this.setState({
                        showLoginView: false
                    });
                }
            }
            else {
                const params = new URLSearchParams(this.props.location.search);
                const username = params.get("username");
                const password = params.get("password");
                this.setState({
                    showLoginView: true,
                    fillUsername: username,
                    fillPassword: password
                });
            }
        })
    }

    setUpBookingListner() {
        const q = query(collection(db, "bookings"), where("end", ">=", Timestamp.fromMillis((Timestamp.now().seconds - (30 * 24 * 60 * 60)) * 1000)));
        onSnapshot(q, querySnapshot  => {
            var events = [];
            querySnapshot.forEach(doc => {
                let bookingData = doc.data();
                let booking = {
                    title: bookingData.title,
                    start: bookingData.start.toDate(),
                    end: bookingData.end.toDate(),
                    id: doc.id,
                    name: bookingData.name,
                    address: bookingData.address,
                    phoneNumber: bookingData.phoneNumber,
                    numberOfRooms: bookingData.numberOfRooms
                };
                events.push(booking);
            });
            this.setState({
                bookings: events
            });
        });
    }

    setUpConfigListner() {
        onSnapshot(doc(db, "config", "config"), doc => {
            let config = doc.data();
            this.setState({
                bookingEnabled: config.bookingEnabled
            });
        });
    }

    openUrl(url) {
        window.open(url, "_self");
    }

    eventStyle = (event, start, end, isSelected) => {
        var isPastEvent = event.end < Timestamp.now().toDate();
        if (event.title == "Untitled") {
            var opacity = isPastEvent ? 0.5 : 1.0;
            return {
                style: {
                    backgroundColor: "#D3CDCF",
                    borderRadius: '6px',
                    opacity: opacity,
                    color: 'black',
                    border: 'solid #A9A5A7',
                    display: 'block'
                }
            };
        }
        else if (isPastEvent) {
            return {
                style: {
                    backgroundColor: "#9C2042",
                    borderRadius: '6px',
                    opacity: 1.0,
                    color: 'white',
                    border: 'solid #891D3B',
                    display: 'block'
                }
            };
        } else {
            return {
                style: {
                    backgroundColor: "#1E5CA7",
                    borderRadius: '6px',
                    opacity: 1.0,
                    color: 'white',
                    border: 'solid #154883',
                    display: 'block'
                }
            };
        }
    }

    handleSlotSelect = ({ start, end }) => {
        if (auth.currentUser == null) {
            return;
        }
        
        const title = window.prompt('New Event Title:');
        if (title) {
            addDoc(collection(db, "bookings"), {
                start: Timestamp.fromDate(start),
                end: Timestamp.fromDate(end),
                title: title
            })
            // .then(docRef => {
            //     console.log("Document written with ID: ", docRef.id);
            // })
            .catch(error => {
                console.error("Error adding document: ", error);
            });
        }
    }

    handleSelect = (event) => {
        this.setState({
            selectedBooking: event
        });
    }

    onLogIn() {
    }

    onHideLoginView() {
    }

    onHideEventView = (event) => {
        this.setState({
            selectedBooking: null,
            showNewEntryView: false
        });
    }

    onDeleteEventRequest = (event) => {
        const confirm = window.confirm('Are you sure you wish to delete this item?');
        if (confirm) {
            deleteDoc(doc(db, "bookings", event.id)).then(function() {
                // console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
        this.setState({
            selectedBooking: null
        });
    }

    onCreateEventRequest = (event) => {
        addDoc(collection(db, "bookings"), {
            start: Timestamp.fromDate(event.start),
            end: Timestamp.fromDate(event.end),
            title: event.title
        })
        .then(docRef => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch(error => {
            console.error("Error adding document: ", error);
        });
        this.setState({
            showNewEntryView: false
        });
    }

    bookingToggled(event) {
        updateDoc(doc(db, "config", "config"), {
            bookingEnabled: !this.state.bookingEnabled
        })
        // .then(function() {
        //     console.log("Config successfully updated!");
        // })
        .catch(function(error) {
            console.error("Error updating config: ", error);
        });
    }
    
    newEntry() {
        if (auth.currentUser == null) {
            return;
        }
        this.setState({
            showNewEntryView: true
        });
    }

    render() {
        let tabData = [
            {title: "New Entry", action: () => this.newEntry()}
        ];

        let dateFormats = {
            timeGutterFormat: 'ha',
            dayFormat: 'ddd'
        }

        return (
            <div className="calendarPage">
                <Helmet>
                <title>End of Lease Carpet Cleaning Bendigo | About Us</title>
                </Helmet>
                <NavBar tabData={tabData} titleTop={"End of Lease"} titleBottom={"Carpet Cleaning Bendigo"} mainAction={() => this.openUrl('/')}/>
                <div className="calendarToolbar">
                    <div>
                        <label className="switchLabel">
                            Booking Enabled:
                        </label>
                        <label className="switch">
                            <input checked={this.state.bookingEnabled} type="checkbox" onChange={this.bookingToggled}/>
                            <span className="slider"/>
                        </label>
                    </div>
                </div>
                <Calendar
                    min={new Date(1972, 0, 1, 6, 0, 0)}
                    max={new Date(1972, 0, 1, 21, 0, 0)}
                    formats={dateFormats}
                    selectable
                    localizer={localizer}
                    events={this.state.bookings}
                    startAccessor="start"
                    endAccessor="end"
                    scrollToTime={Timestamp.now().toDate()}
                    defaultView={Views.WEEK}
                    style={{height: 650}}
                    onSelectEvent={this.handleSelect}
                    onSelectSlot={this.handleSlotSelect}
                    eventPropGetter={this.eventStyle}
                />
                <LogInView fillUsername={this.state.fillUsername} fillPassword={this.state.fillPassword} show={this.state.showLoginView} onLogIn={this.onLogIn} onDone={this.onHideLoginView}/>
                <EventView show={this.state.selectedBooking != null} onDone={this.onHideEventView} onDelete={this.onDeleteEventRequest} event={this.state.selectedBooking}/>
                <NewEventView show={this.state.showNewEntryView} onDone={this.onHideEventView} onCreate={this.onCreateEventRequest}/>
            </div>
        );
    }
}

export default CalendarPage;